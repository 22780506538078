<template>
  <div class="product-lookup margin-80">
    <div class="container">
      <div class="product-lookup-title padding-t-80">
        <div>
          <h1>{{ $t("lookup") }}</h1>
          <div class="pri-line">
          </div>
        </div>

      </div>
      <div class="product-lookup-form">
        <form>

          <div class="product-li">
            <div class="product-item">
              <label>{{ $t("pname") }}</label>
              <div style="position: relative" class="item-input">
                <div class="product-name-select">
                  <select name="productName" v-model="product.product">
                    <option  v-for="(item,index) in productCate" :key="index" :value="item.id">
                       {{item.title}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="product-item">
              <label>{{ $t("email") }}</label>
              <div style="position: relative" class="item-input">
                  <input id="email" v-model="product.email" name="email" autocomplete="off"/>
              </div>
            </div>
          </div>


          <div class="product-item">
            <label>{{ $t("message") }}</label>
            <div class="item-input">
              <textarea class="form-control" v-model="product.message" name="message" id="message"></textarea>
            </div>
          </div>

           <div class="product-form-btn">
              <button type="button" class="submit"  @click="onSubmit()">{{ $t("submit") }}</button>
           </div>

        </form>
      </div>
    </div>

  </div>




  <!-- res 200 pop -->
  <div class="pop" v-if="success">
    {{ $t("success") }}
  </div>
  <!-- err 500 pop -->
  <div class="pop" v-if="miss">
    {{ $t("failed") }}
  </div>




</template>

<script>
import axios from  'axios';

import {getProducts} from "@/network/main";

export default {
  name: "homeForm",
  data() {
    return {
      product: {
        product: '1',
        email: '',
        message: '',
      },
      success: false,
      miss: false,
      productCate:[],

    }
  },
  created() {
    getProducts().then(res => {
      this.productCate = res.data.data.products;
    })
  },

  methods: {
      onSubmit() {
        function shake(elemId) {
          let elem = document.getElementById(elemId)
          if (elem) {
            elem.classList.add('shake')
            elem.classList.add('redBorder')
            setTimeout(()=>{ elem.classList.remove('shake') }, 800)
          }
        }

        //判断email
        // const emailContent = document.getElementById("email");
        if(this.product.email !== "") {
          let reg=/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
          if(!reg.test(this.product.email)){
            // emailContent.style.borderColor = '#ff0000';
            shake('email')
            return false;
          }
        }else {
          // emailContent.style.borderColor = '#ff0000';
          shake('email')
          return false;
        }



        if (this.product.message == ''){
          shake('message')
          return false;
        }


       //params  URLSearchParams对象
      let params = new URLSearchParams();
      params.append('product',this.product.product)
      params.append('email',this.product.email)
      params.append('message',this.product.message)

      axios.post('https://api.layer1hk.com/api/product_lookup', params).then((res)=>{
        console.log(res)
        this.success = true
        if (this.success) {
          setTimeout(() => {
            this.success = false;
            this.$router.push('/');
            Object.assign(this.$data.product, this.$options.data().product) // 部分重置,只重置product里面的data
          }, 2000)
       }
      }).catch(err=> {
        console.log(err)
         this.miss = true
        if (this.miss) {
          setTimeout(() => {
            this.miss = false
          }, 2000)
        }
      })
    },
  }

}
</script>

<style scoped>




/**/
.product-lookup{
  background: url("~@/assets/img/inquire-bg.png") top center no-repeat;
}
.product-lookup-title{
  display: flex;
  justify-content: center;
  color: #fff;
  text-align: center;
}

.pri-line{
  width: 104px;
  height: 4px;
  background: #fff;
  margin: 20px auto;
}

.product-lookup-form{
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 8px 9px rgba(2, 16, 51, 0.12);
  padding: 40px;
  margin-top: 40px;
}

.product-item{

  padding: 10px 0;
}
.item-input{
 padding-top: 10px;
}
.product-item label{
  width: 100%;
 padding-bottom: 5px;
  display: block;
}

.product-name-select select{
  width: 100%;
  height:45px;
  background: #EEEEEE;
  border-radius: 5px;
  box-shadow: none;
  border:none;
  padding: 0 10px;
}

.item-input textarea{
  width: 100%;
  border:none;
  background: #EEEEEE;
  height: 100px; resize: none;padding: 10px;
  border-radius: 5px;
}

.product-form-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.product-form-btn button{

  box-shadow: none;
  cursor: pointer;
}

.product-li{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-li .product-item{
  width: 48%;
}

.product-li .product-item input{
  width: 100%;
  border: none;
  border-radius: 5px;
}


@media only screen and (min-width:0px) and (max-width:767px){
  .product-lookup-form{
    padding: 20px;
    margin-top: 20px;
  }
  .product-lookup-title h1{
    font-size: 24px;
  }

  .product-li{
    flex-wrap: wrap;
  }
  .product-li .product-item{
    width: 100%;
  }


}

</style>