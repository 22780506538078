export default {
    menu:{
        home:"Home",
        about:"About Us",
        products: "Products",
        solutions: "Solutions",
        news:"News",
        case:"Case Study",
        support:"Support",
        inquiry:"Inquiry Form",
        mrtg:"MRTG Login",
        phone:"Support Phone Numbers",
        careers:"Careers",
        partner:"Partner With Us",
        contact:"Contact Us",
    },
    lookup:"Product lookup",
    pname:"Product Name",
    message:"Message",
    submit:"Submit",
    name:"Name",
    email:"Email",
    phone:"Phone",
    topic:"Topic",
    sign:"Sign in",
    jobTitle:"Job Title",
    jobNature:"Job Nature",
    requirement:"Requirement",
    advantage:"Our advantage",
    success:"Email sent successfully",
    failed:"Email sending failed",
    hotline:"Hotline",
    fax:"Fax",
    tel:"Tel",
    general:"General Enquire",
    techincal:"Techincal Support",

}