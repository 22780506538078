
import { createI18n } from 'vue-i18n'

import hk from "./hk"
import en from "./en"

const i18n = createI18n({
    locale: localStorage.getItem('language') || 'en',
    messages:{
        en,
        hk
    }
});
export default i18n;
