<template>
<div>
  <div class="footer">
    <div class="container">
      <div class="footer-item">
        <div class="footer-item-left">
          <img src="@/assets/img/logo.png">
          <div class="footer-item-1">
           <span>
             <i class="iconfont icon-address"></i>
           </span>
            <span>
            {{ basicInfo.address }}
           </span>
          </div>
          <div class="footer-item-1">
           <span>
             <i class="iconfont icon-email-fill"></i>
           </span>
            <span>
           {{ basicInfo.email }}
           </span>
          </div>
          <div class="footer-item-1">
           <span>
             <i class="iconfont icon-phone"></i>
           </span>
            <span>
             {{ basicInfo.tel }}
           </span>
          </div>
        </div>
        <div class="footer-item-right">
          <div class="right-item">
            <h3>{{ $t("menu.home") }}</h3>
            <router-link to="/about">{{ $t("menu.about") }}</router-link>
            <router-link to="/careers">{{ $t("menu.careers") }}</router-link>
            <router-link to="/contact">{{ $t("menu.contact") }}</router-link>
          </div>
          <div class="right-item">
            <h3>{{ $t("menu.products") }}</h3>
           <div @click="goto('/products', '#pro'+ item.id +'')"  v-for="item in productCate" :key="item">
              {{ item.title }}
            </div>

          </div>
          <div class="right-item">
            <h3>{{ $t("menu.solutions") }}</h3>
            <div @click="goto('/solution', '#news')">{{ $t("menu.news") }}</div>
            <div @click="goto('/solution', '#case')">{{ $t("menu.case") }}</div>
          </div>
          <div class="right-item">
            <h3>{{ $t("menu.support") }}</h3>
            <div @click="goto('/support', '#inquiry')">{{  $t("menu.inquiry") }}</div>
            <a href="http://mrtg.layer1hk.com/" target="_blank">{{  $t("menu.mrtg") }}</a>
            <div @click="goto('/support', '#address')">{{  $t("menu.phone") }}</div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="copyright">
    <p>Copyright © 2022 Layer One Limited.</p>
  </div>
</div>




</template>

<script>


import {getProducts, getIndexInfo} from "@/network/main";

export default {
  name: "Footer",
  data(){
    return{
      productCate:[],
      basicInfo: {},
    }
  },
  created() {
    getProducts().then(res => {
      this.productCate = res.data.data.products;
   })

    getIndexInfo().then(res => {
      this.basicInfo = res.data.data.contact_us;
      console.log(this.basicInfo.address)
    })

  },




  methods:{
    //锚链接跳转
    goto(page, selectId) {
      console.log(this.$route.path, "this.$route.path");
      console.log(selectId)
      if (page == this.$route.path) {
        //如果当前已经在这个页面不跳转 直接去
        // let toElement = document.querySelector(selectID);
        let toElement = document.querySelector(selectId);
        toElement.scrollIntoView(true);
      } else {
        //否则跳转路由
        localStorage.setItem("toId", selectId);
        this.$router.push({ path: page });
      }
    },

  }
}
</script>

<style scoped>
.footer{
  padding: 50px 0 30px 0;
  border-bottom: 1px solid #eee;
}

.footer-item{
  display: flex;
  justify-content: space-between;
}

.footer-item-left{
  width: 20%;
  margin-right: 5%;
}

.footer-item-1{
  display: flex;
  font-size: 15px;
  color: #666;
  padding-top: 10px;
}

.footer-item-1 i{
  padding-right: 10px;
  color: #000;
}

.footer-item-right{
  width: 70%;
  display: flex;
}

.right-item{
  flex: 1;
  padding: 0 10px;
}

.right-item h3{
  padding-bottom: 10px;
  color: var(--color-background);
}

.right-item a, .right-item div{
 display: block;
  padding: 6px 0;
  font-size: 14px;
  color: #666;
  cursor: pointer;
}

.right-item a:hover, .right-item div:hover{
  color: var(--color-background);

  animation: fadeInLeft 0.5s 1;
  margin-left: 10px;
}

.copyright p{
  font-size: 14px;
  text-align: center;
  padding: 20px 0;
  color: #999;

}



@-webkit-keyframes fadeInLeft{
  0%{
    -webkit-transform:translate3d(-10px,0,0);
    transform:translate3d(-10px,0,0)
  }
  100%{

    -webkit-transform:none;
    transform:none
  }
}
@keyframes fadeInLeft{
  0%{
    -webkit-transform:translate3d(-10px,0,0);
    -ms-transform:translate3d(-10px,0,0);
    transform:translate3d(-10px,0,0)
  }
  100%{
    -webkit-transform:none;
    -ms-transform:none;
    transform:none
  }
}


@media only screen and (min-width:0px) and (max-width:767px){
  .footer-item-left{
    width: 100%;
    margin-right: 0;
  }
  .footer-item-left img{
    display: none;
  }

  .footer-item-right{
    display: none;
  }

  .footer{
     padding: 15px 0;
  }
}


</style>