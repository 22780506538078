<template>
<div class="header" id="header">
   <div class="container">
       <div class="top">
       <div class="logo">
         <router-link to="/">
           <img src="@/assets/img/logo.png">
         </router-link>
       </div>
       <div class="top-right">
         <div class="lang">
            <a href="https://wa.me/85265349642?text=onlineenquiry" target="_blank" class="whatAPP">
              <img src="@/assets/img/WhatsApp.svg">
            </a>
            <a @click="changeLanguage('hk')" :class="{active:language === 'hk'}">繁體中文</a> 丨
            <a @click="changeLanguage('en')"  :class="{active:language === 'en'}">ENGLISH</a>
         </div>
         <!-- nav-->
         <div class="nav">

           <div class="index-nav">
             <div class="index-nav-frame clearfix">
               <div class="nav-small" tabindex="-1" v-on:click="show = !show">
                 <div class="nav-small-focus" tabindex="-1">
                 </div>
                 <img src="@/assets/img/menu.svg" />
               </div>
               <div v-if="show" class="mobile-nav-only">

                 <div class="index-nav-frame-line" tabindex="-1" @click="mobileClick">
                   <router-link to="/about" :class="{'active': $route.path === '/about'}">{{ $t("menu.about") }}</router-link>
                 </div>
                 <div class="index-nav-frame-line" tabindex="-1">
                   <div @click="mobileClick">
                     <router-link to="/products" :class="{'active': $route.path === '/products'}">
                       {{ $t("menu.products") }}
                     </router-link>
                   </div>
                   <div class="index-nav-frame-line-center">
                     <div class="index-nav-frame-line-li" @click="mobileClick"  v-for="item in productCate" :key="item.id" >
                       <a @click="goto('/products', '#pro'+ item.id +'')">
                         {{ item.title }}
                       </a>
                     </div>
                   </div>
                   <div class="index-nav-frame-line-focus" tabindex="-1"></div>
                 </div>

                 <div class="index-nav-frame-line" tabindex="-1">
                    <div @click="mobileClick">
                     <router-link to="/solution" :class="{'active': $route.path === '/solution'}">
                       {{ $t("menu.solutions") }}
                     </router-link>
                   </div>
                   <div class="index-nav-frame-line-center">
                     <div class="index-nav-frame-line-li" @click="mobileClick">
                       <a @click="goto('/solution', '#news')">
                         {{ $t("menu.news") }}
                       </a>
                     </div>
                     <div class="index-nav-frame-line-li" @click="mobileClick">
                       <a @click="goto('/solution', '#case')">
                         {{ $t("menu.case") }}
                       </a>
                     </div>
                    </div>
                   <div class="index-nav-frame-line-focus" tabindex="-1"></div>
                 </div>
                 <div class="index-nav-frame-line" tabindex="-1" >

                   <div @click="mobileClick">
                     <router-link to="/support" :class="{'active': $route.path === '/support'}">
                       {{ $t("menu.support") }}
                     </router-link>
                   </div>
                   <div class="index-nav-frame-line-center">
                     <div class="index-nav-frame-line-li" @click="mobileClick">
                       <a @click="goto('/support', '#inquiry')">
                         {{ $t("menu.inquiry") }}
                       </a>
                     </div>
                     <div class="index-nav-frame-line-li" @click="mobileClick">
                       <a @click="goto('/support', '#sign')">
                         {{ $t("menu.mrtg") }}
                       </a>
                     </div>
                     <div class="index-nav-frame-line-li" @click="mobileClick">
                       <a @click="goto('/support', '#address')" style="display: flex">
                         {{ $t("menu.phone") }}
                       </a>
                     </div>
                   </div>
                    <div class="index-nav-frame-line-focus" tabindex="-1"></div>
                 </div>
                 <div class="index-nav-frame-line" tabindex="-1" @click="mobileClick">
                   <router-link to="/careers" :class="{'active': $route.path === '/careers'}">
                     {{ $t("menu.careers") }}
                   </router-link>
                 </div>
                 <div class="index-nav-frame-line" tabindex="-1"  @click="mobileClick">
                   <router-link to="/partner" :class="{'active': $route.path === '/partner'}">
                     {{ $t("menu.partner") }}
                   </router-link>
                 </div>
                 <div class="index-nav-frame-line last-index-nax" tabindex="-1" @click="mobileClick">
                   <router-link to="/contact"  :class="{'active': $route.path === '/contact'}">
                     {{ $t("menu.contact") }}
                   </router-link>
                 </div>
               </div>

             </div>
           </div>

         </div>


       </div>
     </div>

     </div>
   </div>


</template>

<script>
import i18n from "@/lang/index";
import {getProducts} from "@/network/main";



export default {
  name: "Header",
  data() {
    return {
      language:localStorage.getItem("language") == null ? 'en' :localStorage.getItem("language"),
      show: false,
      isShow:true,
      productCate:[],
   }
  },
  created() {
    //产品下拉
    getProducts().then(res => {
      this.productCate = res.data.data.products;
    })

    //判断屏幕宽度,是否默认显示下拉
    if(window.innerWidth >= 768){
      this.show= true;
    }else{
      this.isShow= false;
    }

    //导航吸顶
    window.onscroll = function () {
      const topScroll = document.documentElement.scrollTop;
      const header = document.getElementById("header");

      if (topScroll > 50) {
        header.style.background = '#fff';
      } else {
        header.style = ''
      }
    }
  },


  methods: {

    mobileClick() {  //手机端导航点击后默认不显示下拉
      if(window.innerWidth < 767){
        this.show= false;
      }
    },
   //语言切换
    changeLanguage(language) {
      localStorage.setItem("language", language)

      if (language == "en") {
        i18n.global.locale = "en";
        localStorage.setItem('language',"en")
        console.log(i18n.global.locale)
        location.reload();
      }  else if (language == "hk") {

        i18n.global.locale = "hk";
        localStorage.setItem('language',"hk")
        location.reload();
      }
    },
   //锚链接跳转
    goto(page, selectId) {
      console.log(this.$route.path, "this.$route.path");
      console.log(selectId)
      if (page !== this.$route.path) {
         //否则跳转路由
        localStorage.setItem("toId", selectId);
        this.$router.push({ path: page });

      } else {
        let toElement = document.querySelector(selectId);
        setTimeout(function() {
          toElement.scrollIntoView({
            behavior: "smooth"
          });
        }, 100);
      }
  },

  },



}



</script>

<style scoped>


</style>