import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue')
   },
   {
     path: '/products',
     name: 'Products',
     component: () => import('@/views/Products.vue')
   },
   {
      path: '/solution',
      name: 'Solution',
      component: () => import('@/views/Solution.vue')
   },
   {
        path: '/newsMore/:id',
        name: 'SolutionNews',
        component: () => import('@/views/solutionDetail/solutionNews.vue')
   },
    {
        path: '/caseMore/:id',
        name: 'SolutionCase',
        component: () => import('@/views/solutionDetail/solutionCase.vue')
    },





    {
        path: '/support',
        name: 'Support',
        component: () => import('@/views/Support.vue')
    },
    {
        path: '/careers',
        name: 'Careers',
        component: () => import('@/views/Careers.vue')
    },
    {
        path: '/partner',
        name: 'Partner',
        component: () => import('@/views/Partner.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('@/views/Contact.vue')
    },


]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  //scrollBehavior滚动行为
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 } //期望滚动到哪个的位置
    }
  },
})


//路由点击跳转后页面回到顶部
router.afterEach((to, form, next) => {
  window.scrollTo(0, 0)
  next()
})

export default router
