<template>

  <div class="main" v-if="isShow">
     <loading/>
  </div>
<div class="index">
  <home-solution
      :text_one="text_one"
      :text_two="text_two"
      :image_one="image_one"
  />
  <home-cate
      :cate1="cate1"
      :cate2="cate2"
      :cate3="cate3"
      :cate4="cate4"
  />

  <home-form/>

  <home-contact
      :image_two="image_two"
      :image_three="image_three"
      :general_enquire="general_enquire"
      :technical_support="technical_support"
   />
</div>
</template>

<script>
import homeSolution from "@/views/homeDetail/homeSolution";
import homeCate from "@/views/homeDetail/homeCate";
import homeForm from "@/views/homeDetail/homeForm";
import homeContact from "@/views/homeDetail/homeContact";
import loading from "@/components/loading";

import {getIndexInfo} from "@/network/main";


export default {
  name: "Home",
  components:{
    homeSolution,
    homeCate,
    homeForm,
    homeContact,
    loading
  },
  data() {
    return {
      text_one: {},
      text_two: {},
      cate1: {},
      cate2: {},
      cate3: {},
      cate4: {},
      image_one:{},
      image_two:{},
      image_three:{},
      general_enquire:{},
      technical_support:{},
      isShow:true
     }
  },
  created() {
    this.getIndexInfo()
  },
  methods: {
    getIndexInfo() {
      getIndexInfo().then(res =>{
        this.text_one = res.data.data.data.text_one;
        this.text_two = res.data.data.data.text_two;
        this.image_one =  res.data.data.data.image_one;
        this.cate1 = res.data.data.data.bar[0];
        this.cate2 = res.data.data.data.bar[1];
        this.cate3 = res.data.data.data.bar[2];
        this.cate4 = res.data.data.data.bar[3];
        this.image_two = res.data.data.data.image_two;
        this.image_three = res.data.data.data.image_three;
        this.general_enquire = res.data.data.data.contact_us.general_enquire;
        this.technical_support = res.data.data.data.contact_us.technical_support;

        // this.$refs.isLoading.loadingShow = false;
        this.isShow = false;
      })
    }
  }
}
</script>

<style scoped>





</style>