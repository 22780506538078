export default {
    menu:{
        home:"首頁",
        about:"關於我們",
        products: "產品資料",
        solutions: "解決方案",
        news:"最新消息",
        case:"個案研究",
        support:"技術支援",
        inquiry:"查詢表格",
        mrtg:"登入MRTG系統",
        phone:"支援熱線",
        careers:"人才招聘",
        partner:"合作機會",
        contact:"聯絡我們",
    },
    lookup:"產品查詢",
    pname:"產品名稱",
    message:"信息",
    submit:"提交",
    name:"姓名",
    email:"電郵",
    phone:"電話",
    topic:"標題",
    sign:"登入",
    jobTitle:"職位名稱",
    jobNature:"工作性質",
    requirement:"工作要求",
    advantage:"我們的優勢",
    success:"郵件發送成功",
    failed:"郵件發送失敗",
    hotline:"熱線",
    fax:"傳真",
    tel:"電話",
    general:"一般諮詢",
    techincal:"技術支援",

}