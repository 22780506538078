import {request} from "@/network/request";

//index
export function  getIndexInfo() {
    return request({
        url: 'index'
    })
}

//product
export function  getProducts() {
    return request({
        url: 'products'
    })
}


//about
export function  getAbout() {
    return request({
        url: 'about_us'
    })
}

//solutions
export function  getSolutions() {
    return request({
        url: 'solutions'
    })
}

//solutions-news-more
export function  getNews(id) {
    return request({
        url: 'solution_new/'+ id,
  })
}

//solutions-news-more
export function  getCase(id) {
    return request({
        url: 'solution_case_study/'+ id,
    })
}


//support
export function  getSupport() {
    return request({
        url: 'support',
    })
}


//career
export function  getCareer() {
    return request({
        url: 'careers',
    })
}

//Partner
export function  getPartner() {
    return request({
        url: 'partner_with_us',
    })
}


//Contact
export function  getContact() {
    return request({
        url: 'contact_us',
    })
}











