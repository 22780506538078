<template>

  <div class="loading">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
  </div>


</template>

<script>
export default {
  name: "loading",
}
</script>

<style scoped>


.loading{
  width: 70px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  justify-content: space-between;
}
.loading span{
  width: 8px;
  height: 100%;
  border-radius: 4px;
  background: #fff;
  -webkit-animation: load 1s ease infinite;
  animation: load 1s ease infinite;
}
@-webkit-keyframes load{
  0%,100%{
    height: 40px;
    background: #fff;
  }
  50%{
    height: 70px;
    margin: -15px 0;
    background: lightgreen;
  }
}
.loading span:nth-child(2){
  -webkit-animation-delay:0.2s;
  animation-delay:0.2s;
}
.loading span:nth-child(3){
  -webkit-animation-delay:0.4s;
  animation-delay:0.4s;
}
.loading span:nth-child(4){
  -webkit-animation-delay:0.6s;
  animation-delay:0.6s;
}
.loading span:nth-child(5){
  -webkit-animation-delay:0.8s;
  animation-delay:0.8s;
}
</style>