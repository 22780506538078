<template>
  <div class="container">
    <div class="index-cate">
      <ul>
        <li>
          <router-link to="/products">
            <div class="cate-icon">
              <i class="iconfont icon-wenjianjia"></i>
            </div>
            <div class="cate-txt">
              <h3>{{cate1.title}}</h3>
              <p>{{cate1.description }}</p>
            </div>
            <div class="cate-go">
              <i class="iconfont icon-jiantou_xiangyou_o"></i>
            </div>
          </router-link>
        </li>
        <li>
          <router-link to="/solution">
            <div class="cate-icon">
              <i class="iconfont icon-SOLUTION_PRESIGN"></i>
            </div>
            <div class="cate-txt">
              <h3>{{cate2.title}}</h3>
              <p>{{cate2.description }}</p>
            </div>
            <div class="cate-go">
              <i class="iconfont icon-jiantou_xiangyou_o"></i>
            </div>
          </router-link>
        </li>
        <li>
          <router-link to="/support">
            <div class="cate-icon">
              <i class="iconfont icon-wentizhichi"></i>
            </div>
            <div class="cate-txt">
              <h3>{{cate3.title}}</h3>
              <p>{{cate3.description }}</p>
            </div>
            <div class="cate-go">
              <i class="iconfont icon-jiantou_xiangyou_o"></i>
            </div>
          </router-link>
        </li>
        <li>
          <a href="http://mrtg.layer1hk.com/" target="_blank">
            <div class="cate-icon">
              <i class="iconfont icon-diannao"></i>
            </div>
            <div class="cate-txt">
              <h3>{{cate4.title}}</h3>
              <p>{{cate4.description }}</p>
            </div>
            <div class="cate-go">
              <i class="iconfont icon-jiantou_xiangyou_o"></i>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
export default {
  name: "homeCate",
  props:{
    cate1:{
      default() {
        return { }
      }
    },
    cate2:{
      default() {
        return { }
      }
    },
    cate3:{
      default() {
        return { }
      }
    },
    cate4:{
      default() {
        return { }
      }
    },
  }
}
</script>

<style scoped>

.index-cate ul {
  display: flex;
  flex-wrap: wrap;
}
.index-cate ul li{
  width: 46%;
  margin: 20px 2%;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 6px 9px rgba(1, 9, 30, 0.2);
  border-radius: 10px;
}
.cate-icon{
  width: 36px;
  height: 36px;
  background: var(--color-background);
  border-radius: 100%;
  line-height: 36px;
  color: #fff;
  text-align: center;
}

.cate-icon i{
  font-size: 20px;
}

.cate-txt h3{
  padding: 10px 0;

}

.cate-txt p{
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 70px;
}

.cate-go{
  margin-top: 20px;
  position: relative;
}

.cate-go i{
  font-size: 30px;
  color: var(--color-background);

}


.index-cate ul li:hover{
  background: url("~@/assets/img/four-bg.png") no-repeat;
  -moz-transition:ease 0.4s;
  -o-transition:ease 0.4s;
  -webkit-transition:ease 0.4s;
  transition:ease 0.4s;
 -webkit-transform: scale(1.08);
  transform: scale(1.08);
}


.index-cate ul li:hover .cate-icon{
  background: #fff;
  color: var(--color-background);
}

.index-cate ul li:hover h3, .index-cate ul li:hover p, .index-cate ul li:hover .cate-go i{
  color: #fff;
}

.index-cate ul li:hover .cate-go {
  animation: fadeInLeft 1s 1;
  margin-left: 10px;
}


@-webkit-keyframes fadeInLeft{
  0%{
    -webkit-transform:translate3d(-20px,0,0);
    transform:translate3d(-20px,0,0)
  }
  100%{

    -webkit-transform:none;
    transform:none
  }
}
@keyframes fadeInLeft{
  0%{
   -webkit-transform:translate3d(-20px,0,0);
   -ms-transform:translate3d(-20px,0,0);
    transform:translate3d(-20px,0,0)
  }
  100%{
    -webkit-transform:none;
    -ms-transform:none;
    transform:none
  }
}




@media only screen and (min-width:0px) and (max-width:767px){
  .index-cate ul li{
    width: 100%;

   }

}

</style>