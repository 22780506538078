<template>
  <div class="index-solution-bg">
    <div class="container">
      <div class="index-solution padding-80">
        <div class="index-solution-txt">
          <h1>{{ text_one }}</h1>
          <p>
            {{text_two}}
           </p>
          <div class="solution-btn">
            <router-link to="/contact">
              {{ $t("menu.contact") }}
            </router-link>
          </div>
        </div>
        <div class="solution-img">
          <img :src="image_one">
        </div>

      </div>
    </div>
  </div>


</template>

<script>
export default {
  name: "homeSolution",
  props:{
    text_one:{
      default() {
        return{}
      }
    },
    text_two:{
      default() {
        return{}
      }
    },
    image_one:{
      default() {
        return{ }
      }
    }

  }
}
</script>

<style scoped>
.index-solution-bg{
  padding-top: 94px;
  background: url("~@/assets/img/top-img.png") top right no-repeat;
}

.index-solution{
  display: flex;
 align-items: center;
}

.index-solution-txt{
  width: 45%;margin-right:10%;
}

.index-solution-txt h1{
  font-size: 60px;
  font-weight: bold;
}

.index-solution-txt p{
  font-size: 18px;
  color: #666;
  line-height: 1.8;
  padding: 20px 0;
  white-space: pre-wrap;
}
.solution-btn{
  margin-top: 40px;
}

.solution-btn a{
  font-size: 20px;
  padding: 20px 40px;
  color: #fff;
  background: var(--color-background);
  border-radius:40px;
  display: inline-block;
}


.solution-btn a{
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;

  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;

}
.solution-btn a:before{
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0068d7;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  border-radius:40px;
}
.solution-btn a:hover:before, .solution-btn a:focus:before, .solution-btn a:active:before{
  -webkit-transform: scale(1);
  transform: scale(1);
  border-radius:40px;
}


.solution-img{
  /*animation:fadeInRight 0.8s ease-in-out 1;*/
}


.solution-img {
  -webkit-animation: bounce 1s ease-in-out 0s infinite alternate;
  animation: bounce 1s ease-in-out 0s infinite alternate;
}
.main-header.sticky .logo .main-logo2 {
  display: none;
}
@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  100% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px)
  }
}
@-webkit-keyframes fadeInRight{
  0%{
    opacity:0;-webkit-transform:translate3d(100%,0,0);transform:translate3d(100%,0,0)
  }
  100%{
    opacity:1;-webkit-transform:none;transform:none}
}
@keyframes fadeInRight{
  0%{
    opacity:0;-webkit-transform:translate3d(100%,0,0);
    -ms-transform:translate3d(100%,0,0);transform:translate3d(100%,0,0)
  }
  100%{
    opacity:1;-webkit-transform:none;-ms-transform:none;transform:none}
}

@-webkit-keyframes pulse{
  0%{
    -webkit-transform:scale3d(1,1,1);
    transform:scale3d(1,1,1)
  }
  50%{
    -webkit-transform:scale3d(1.2,1.2,1.2);
    transform:scale3d(1.2,1.2,1.2);
  }
  100%{
    -webkit-transform:scale3d(1,1,1);
    transform:scale3d(1,1,1)}
}
@keyframes pulse{
  0%{-webkit-transform:scale3d(1,1,1);
  -ms-transform:scale3d(1,1,1);
    transform:scale3d(1,1,1)}
  50%{
    -webkit-transform:scale3d(1.2,1.2,1.2);
    -ms-transform:scale3d(1.2,1.2,1.2);
    transform:scale3d(1.2,1.2,1.2);
  }
  100%{
    -webkit-transform:scale3d(1,1,1);
    -ms-transform:scale3d(1,1,1);
    transform:scale3d(1,1,1)}
   }

@media only screen and (min-width:0px) and (max-width:768px){
  .index-solution-bg{
    padding-top: 0;
    background: none;
  }


  .index-solution{
    padding: 20px 0;
    flex-wrap: wrap;
  }

  .index-solution-txt{
    width: 100%;
    margin: 0;
  }

  .solution-img{
    display: none;
  }
  .index-solution-txt h1{
    font-size:30px;
  }
  .solution-btn{
    margin-top: 0;
  }

  .solution-btn a{
    font-size: 16px;
    padding: 15px 30px;
    border-radius: 40px;
  }
}

</style>