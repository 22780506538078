<template>
<div class="container">
  <div class="home-contact padding-80">
    <div class="home-contact-img">
      <img :src="image_two">
    </div>
    <div class="home-contact-text">
      <div class="home-contact-item">
         <p><span>{{ $t("techincal") }}:</span></p>
        <p>{{ $t("tel") }}: {{ technical_support.tel }}</p>
        <p>{{ $t("fax") }}: {{technical_support.fax}}</p>
        <p>{{ $t("email") }}: {{technical_support.email}}</p>
      </div>
      <div class="home-contact-item">
        <p><span>{{ $t("general") }}:</span></p>
        <p>{{ $t("tel") }}: {{ general_enquire.tel }}</p>
        <p>{{ $t("fax") }}: {{general_enquire.fax}}</p>
        <p>{{ $t("email") }}: {{general_enquire.email}}</p>
      </div>
      <div class="home-contact-btn common-btn">
        <router-link to="/contact">
          {{ $t("menu.contact") }}
        </router-link>
      </div>

    </div>
  </div>
</div>

  <div class="index-single-img">
    <img :src="image_three">
  </div>
</template>

<script>
export default {
  name: "homeContact",
  props:{
    image_two:{
      default() {
        return { }
      }
    },
    image_three:{
      default() {
        return { }
      }
    },
    general_enquire:{
      default() {
        return { }
      }
    },
    technical_support:{
      default() {
        return { }
      }
    },

  }
}
</script>

<style scoped>
.home-contact{
  display: flex;
  align-items: center;
  justify-content:flex-start;
}
.home-contact-img{
  width: 50%;
  margin-right: 40px;
}

.home-contact-item{
  padding: 10px 0;
}

.home-contact-item span{
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 10px;
  display: block;
}

.home-contact-item p{
  padding: 2px 0;
}

.home-contact-btn{
  margin-top: 20px;
}

.index-single-img{
  display: flex;
  align-items: center;
  justify-content: center;
}


@media only screen and (min-width:0px) and (max-width:767px){
  .home-contact{
    flex-wrap: wrap;
  }

  .home-contact-img{
    width: 100%;
  }

  .home-contact-btn{
    margin-top: 10px;
  }



}

</style>